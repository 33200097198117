import React, { useState } from "react";
import { Button } from "react-bootstrap";
import fetchData from "../../Lib/fetchData";

const StuffRow = ({
                      id,
                      title,
                      category,
                      priceFromOne,
                      priceFromFive,
                      priceFromTen,
                      description,
                      discount,
                      isDiscPrices,
                      disabled,
                      notAvailable,
                      toast,
                      image,
                      onSelectChange
                  }) => {
    const [selected, setSelected] = useState(false);
    const [stateDisabled, setStateDisabled] = useState(disabled);
    const [stateNotAvail, setStateNotAvail] = useState(notAvailable);
    const [stateTitle, setStateTitle] = useState(title);
    const [stateDescription, setStateDescription] = useState(description);
    const [statePriceFromOne, setStatePriceFromOne] = useState(priceFromOne);
    const [statePriceFromFive, setStatePriceFromFive] = useState(priceFromFive);
    const [statePriceFromTen, setStatePriceFromTen] = useState(priceFromTen);
    const [stateDiscount, setStateDiscount] = useState(discount);
    const [deleteCount, setDeleteCount] = useState(0);
    const [stateIsDiscountPrice, setStateIsDiscountPrice] = useState(isDiscPrices);

    const [selectedFile, setSelectedFile] = useState(null);
    const [localPreviewUrl, setLocalPreviewUrl] = useState(null);

    const existingImageUrl = image
        ? `${process.env.REACT_APP_AWS_URL}/images/${image}`
        : null;

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setSelectedFile(file);
        setLocalPreviewUrl(URL.createObjectURL(file));
    };

    const handleCheckboxChange = (e) => {
        setSelected(e.target.checked);
        if (onSelectChange) {
            onSelectChange(id, e.target.checked);
        }
    };

    const deleteStuff = async () => {
        if (deleteCount === 0) {
            toast("Нажмите ещё раз чтобы подтвердить удаление товара");
            return setDeleteCount((prevState) => ++prevState);
        }
        if (deleteCount === 1) {
            const res = await fetchData(`/admin/stuff/${id}`, "DELETE", {}, true);
            if (res.status === 204) {
                toast("Товар успешно удален");
            }
        }
    };

    const editStuff = async () => {
        const updatedStuff = {};

        if (stateDisabled !== disabled) updatedStuff.disabled = stateDisabled;
        if (stateIsDiscountPrice !== isDiscPrices)
            updatedStuff.isDiscountPrice = stateIsDiscountPrice;
        if (stateNotAvail !== notAvailable)
            updatedStuff.notAvailable = stateNotAvail;
        if (stateTitle !== title) updatedStuff.title = stateTitle;
        if (stateDescription !== description)
            updatedStuff.description = stateDescription;
        if (statePriceFromOne !== priceFromOne)
            updatedStuff.priceFromOne = Number(statePriceFromOne);
        if (statePriceFromFive !== priceFromFive)
            updatedStuff.priceFromFive = Number(statePriceFromFive);
        if (statePriceFromTen !== priceFromTen)
            updatedStuff.priceFromTen = Number(statePriceFromTen);
        if (stateDiscount !== discount) updatedStuff.discount = stateDiscount;

        const res = await fetchData(
            `/admin/stuff/${id}`,
            "PATCH",
            { ...updatedStuff },
            true
        );

        if (res.status === 200) {
            toast("Товар успешно изменён");
            if (selectedFile) {
                try {
                    const stuffId = id;
                    const baseUrl = process.env.REACT_APP_SERVER_URL;
                    const formData = new FormData();
                    formData.append("file", selectedFile);
                    formData.append("entity", "stuff");

                    const uploadResponse = await fetch(`${baseUrl}/media/${stuffId}`, {
                        method: "POST",
                        body: formData,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("adminToken"),
                        },
                    });

                    if (uploadResponse.status === 201) {
                        toast("Изображение успешно загружено");
                    } else {
                        toast("Ошибка! Максимальный размер изображения 200КБ");
                    }
                } catch (err) {
                    toast("Ошибка при загрузке изображения");
                }
            }
        } else {
            toast("Ошибка при изменении товара");
        }
    };

    return (
        <tr>
            <td>
                <input
                    type="checkbox"
                    checked={selected}
                    onChange={handleCheckboxChange}
                />
            </td>
            <td>{id}</td>
            <td>
                <img
                    src={selectedFile ? localPreviewUrl : existingImageUrl}
                    alt="preview"
                    style={{ width: "100px", height: "auto", display: "block" }}
                />
                <input type="file" onChange={handleFileChange} />
            </td>
            <td>
                <input
                    type="text"
                    className="form-control"
                    value={stateTitle}
                    onChange={(e) => setStateTitle(e.target.value)}
                />
            </td>
            <td>
                <input
                    type="text"
                    className="form-control"
                    value={stateDescription}
                    onChange={(e) => setStateDescription(e.target.value)}
                />
            </td>
            <td>
                <input
                    type="text"
                    className="form-control"
                    value={statePriceFromOne}
                    onChange={(e) => setStatePriceFromOne(e.target.value)}
                />
            </td>
            <td>
                <input
                    type="text"
                    className="form-control"
                    value={statePriceFromFive}
                    onChange={(e) => setStatePriceFromFive(e.target.value)}
                />
            </td>
            <td>
                <input
                    type="text"
                    className="form-control"
                    value={statePriceFromTen}
                    onChange={(e) => setStatePriceFromTen(e.target.value)}
                />
            </td>
            <td>
                <input
                    type="checkbox"
                    className="form-check-input"
                    checked={stateIsDiscountPrice}
                    onChange={() =>
                        setStateIsDiscountPrice((prevState) => !prevState)
                    }
                />
            </td>
            <td>
                <input
                    type="checkbox"
                    className="form-check-input"
                    checked={stateDisabled}
                    onChange={() => setStateDisabled((prevState) => !prevState)}
                />
            </td>
            <td>
                <input
                    type="checkbox"
                    className="form-check-input"
                    checked={stateNotAvail}
                    onChange={() => setStateNotAvail((prevState) => !prevState)}
                />
            </td>
            <td>
                <Button variant="primary" onClick={editStuff}>
                    ✓
                </Button>
            </td>
            <td>
                <Button variant="danger" onClick={deleteStuff}>
                    X
                </Button>
            </td>
        </tr>
    );
};

export default StuffRow;
