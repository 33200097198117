import React, { useEffect, useState } from "react";
import fetchData from "../../Lib/fetchData";
import Board from "react-trello";


const colors = {
    processing: "#ffb6b6",
    active: "#b6ccff",
    out_for_delivery: "#fffaa7",
    delivered: "#b6ffc0",
    cancelled: "#ff3333",
};

const AdminCouriers = () => {
  const [orders, setOrders] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [data, setData] = useState({
    lanes: [
      {
        id: "all-orders",
        title: "Все заказы",
        label: "",
        cards: [],
      },
    ],
  });
  const applyStyles = () => {
    const colorList = [
      "#FFB6C1", "#FFD700", "#FFA07A", "#E0FFFF", "#98FB98",
      "#AFEEEE", "#D3D3D3", "#FFE4B5", "#FFDEAD", "#F0E68C",
      "#D8BFD8", "#FF6347", "#FFA500", "#40E0D0", "#EE82EE",
    ];

    const headerElements = document.querySelectorAll("#root > div > div > div > section");

    headerElements.forEach(headerElement => {

      const childElements = headerElement.querySelectorAll('article');
      childElements.forEach(child => {
        child.getElementsByTagName('header')[0].style.display = 'block';
        const status = child.getElementsByTagName('div')[1].textContent.split('-')[0];
          console.log(status)
        const color = colors[status.trim()];
          console.log(color)
        child.getElementsByTagName('header')[0].style.background = color;
        child.getElementsByTagName('header')[0].style.padding = '6px';
        child.getElementsByTagName('header')[0].style.borderRadius = '8px 8px 0 0';
        child.getElementsByTagName('header')[0].style.overflowX = 'auto';
      });
    });

    // headerElements.forEach(headerElement => {
    //   const randomColor = colorList[Math.floor(Math.random() * colorList.length)];
    //   headerElement.style.background = randomColor;
    //   headerElement.style.padding = '6px';
    //   headerElement.style.borderRadius = '8px 8px 0 0';
    // });

    // const headerElements2 = document.querySelectorAll("div.sc-jsEeTM.fZRLhr");
    // headerElements2.forEach(headerElement => {
    //   headerElement.style.padding = '10px';
    // });
    //
    // const headerElements3 = document.querySelectorAll("article.sc-csKJxZ.sc-eTNRI.eJNaHL.gJtTOa.react-trello-card");
    // headerElements3.forEach(headerElement => {
    //   headerElement.style.maxWidth = '310px';
    //   headerElement.style.overflowX = 'auto';
    // });
    //
    // const el = document.querySelectorAll("#root > div > div > div > section:nth-child(1) > div > div > div:nth-child(1) > article > header");
    // el.forEach(headerElement => {
    //   headerElement.style.maxWidth = '310px';
    //   headerElement.style.overflowX = 'auto';
    // });
  };

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0) {
          applyStyles();
        }
      });
    });

    observer.observe(document.getElementById('root'), {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    const getShifts = async () => {
      const res = await fetchData("/shifts?isActive=true", "GET");
      setShifts(await res.json());
    };

    const getOrders = async () => {
      const res = await fetchData("/admin/orders", "GET", {}, true);

      setCardData(
        (await res.json())
          .reverse()
          .filter(
            (order) =>
              (order.deliveryType === "delivery" ||
                order.deliveryType === "pick_up") &&
              order.status[order.status.length - 1].status === "processing",
          )
          .map((order) => {
            if (order.deliveryType === "delivery") {
              return {
                id: order._id,
                title: order.address?.address,
                description: `${order.status[order.status.length - 1].status}
----------------
${order.address?.address}

${order.orderedStuff
  .map(
    (v) =>
      `${v.title} ${v.description != undefined ? `${v.description}` : ""} - ${
        v.quantity
      } шт.`,
  )
  .join("\n")}

${order.phoneNumber}
Сумма ${order.price.totalPrice}
----------------
${order.comment}
`,
              };
            } else if (order.deliveryType === "pick_up") {
              return {
                id: order._id,
                title: order._id,
                description: `${order.status[order.status.length - 1].status}
----------------
Самовывоз ${order.pickUpLocation}\n

${order.orderedStuff
  .map(
    (v) =>
      `${v.title} ${v.description != undefined ? `${v.description}` : ""} - ${
        v.quantity
      } шт.`,
  )
  .join("\n")}

${order.phoneNumber}
Сумма ${order.price.totalPrice}
----------------
${order.pickUpTime}
${order.comment}
`,
              };
            }
          }),
      );
    };

    getOrders();
    getShifts();
  }, []);

  useEffect(() => {
    setData({
      lanes: [
        {
          id: "all-orders",
          title: "Заказы в обработке",
          label: "",
          cards: cardData,
        },
        ...shifts.map((courier) => ({
          id: courier._id,
          title: courier.name,
          label: "",
          cards: courier.activeOrders.map((order) => {
            return {
              id: order._id,
              title: order.address?.address || "",
              description: `${order.status[order.status.length - 1].status}
----------------
${order.address?.address}

${order.orderedStuff
  .map(
    (v) =>
      `${v.title} ${v.description != undefined ? `${v.description}` : ""} - ${
        v.quantity
      } шт.`,
  )
  .join("\n")}

${order.phoneNumber}
Сумма ${order.price.totalPrice}
----------------
${order.comment}
`,
            };
          }),
        })),
      ],
    });
  }, [shifts, cardData]);

  const customLaneStyle = (id) => {
    if (id === "all-orders") {
      return {
        textAlign: "center",
        color: "white",
        padding: "0",
        borderRadius: "8px",
      };
    }
    return {};
  };

  return (
    <>
      <section className="main">
        <header className="main-header">Просмотр курьеров</header>
      </section>
      <Board
        laneStyle={customLaneStyle("all-orders")}
        cardStyle={{ width: "100%", borderRadius: "8px", textAlign: "start" }}
        style={{ background: "#dadada" }}
        data={data}
        onCardMoveAcrossLanes={async (fromLaneId, toLaneId, cardId, index) => {

        if (fromLaneId === toLaneId) {
            // Перемещение внутри одного столбца
            const updatedLane = data.lanes.find((lane) => lane.id === fromLaneId);
            const updatedCards = [...updatedLane.cards];
            const [movedCard] = updatedCards.splice(
                updatedCards.findIndex((card) => card.id === cardId),
                1,
            );
            updatedCards.splice(index, 0, movedCard);

            const payload = updatedCards.map((card, idx) => ({
                orderId: card.id,
                priority: idx + 2,
            }));

            await fetchData(`/shifts/${fromLaneId}`, "PATCH", payload, true);

            setData({
                ...data,
                lanes: data.lanes.map((lane) =>
                    lane.id === fromLaneId ? { ...lane, cards: updatedCards } : lane,
                ),
            });
        } else {
            if (fromLaneId === "all-orders") {
            await fetchData(
              `/order/courier/${shifts.find(shift => shift._id === toLaneId).courierId}`,
              "PATCH",
              {
                orderId: cardId,
              },
              true,
            );
          } else {
            if (toLaneId !== "all-orders") {
              await fetchData(
                `/order/changeCourier/${shifts.find(shift => shift._id === fromLaneId).courierId}/${shifts.find(shift => shift._id === toLaneId).courierId}`,
                "PATCH",
                {
                  orderId: cardId,
                },
                true,
              );
            } else {
              await fetchData(
                `/order/cancel/courier/${shifts.find(shift => shift._id === fromLaneId).courierId}`,
                "PATCH",
                {
                  orderId: cardId,
                },
                true,
              );
            }
          }
        }
        }}
      />
      <style jsx>{`
        .main {
          background-color: #dadada!important;
          display: flex!important;
          padding-bottom: 50px!important;
          flex-direction: column!important;
          color: #000!important;
        }

        .main-header {
          background-color: #b5b5b5;
          width: 100%;
          text-align: center;
          padding: 37px 60px;
          font:
            600 32px/25% Montserrat,
            -apple-system,
            Roboto,
            Helvetica,
            sans-serif;
          background-color: #b5b5b5!important;
          width: 100%!important;
          text-align: center!important;
          padding: 37px 60px!important;
          font: 600 32px/25% Montserrat, -apple-system, Roboto, Helvetica, sans-serif!important;
        }
      `}</style>
    </>
  );
};

export default AdminCouriers;
