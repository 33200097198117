import React, { useEffect, useState } from "react";
import fetchData from "../../Lib/fetchData";
import { Button, ButtonGroup, Table, Form } from "react-bootstrap";
import StuffRow from "../../Components/Admin/StuffRow";
import { toast, ToastContainer } from "react-toastify";

let prevStuff = [];

const categories = [
  { name: "Снюс", value: "snus" },
  { name: "Поды", value: "pods" },
  { name: "Жидкости", value: "liquids" },
  { name: "Устройства", value: "devices" },
  // Добавьте другие категории при необходимости
];

const EditStuff = () => {
  const [filter, setFilter] = useState("");
  const [stuff, setStuff] = useState([]);
  const [currId, setCurrId] = useState(1);
  const [category, setCategory] = useState("");

  // Состояние для выбранных строк (ID товаров)
  const [selectedRows, setSelectedRows] = useState([]);

  // Состояния для массового редактирования
  const [bulkDiscount, setBulkDiscount] = useState("");
  const [bulkDisabled, setBulkDisabled] = useState(false);
  const [bulkNotAvailable, setBulkNotAvailable] = useState(false);
  const [bulkModel, setBulkModel] = useState("");
  const [bulkTitle, setBulkTitle] = useState("");
  const [bulkNewTitle, setBulkNewTitle] = useState("");
  const [bulkPriceFromOne, setBulkPriceFromOne] = useState("");
  const [bulkPriceFromFive, setBulkPriceFromFive] = useState("");
  const [bulkPriceFromTen, setBulkPriceFromTen] = useState("");
  const [bulkIsTobacco, setBulkIsTobacco] = useState(false);
  const [bulkNicotineContent, setBulkNicotineContent] = useState("");
  const [bulkNumberOfUses, setBulkNumberOfUses] = useState("");
  const [bulkBatteryCapacity, setBulkBatteryCapacity] = useState("");
  const [bulkCartridgeCapacity, setBulkCartridgeCapacity] = useState("");
  const [bulkLiquidCapacity, setBulkLiquidCapacity] = useState("");
  const [bulkColor, setBulkColor] = useState("");
  const [bulkConfiguration, setBulkConfiguration] = useState("");
  const [bulkMaxPower, setBulkMaxPower] = useState("");
  const [bulkCoilType, setBulkCoilType] = useState("");
  const [bulkResistance, setBulkResistance] = useState("");
  const [bulkCompatibility, setBulkCompatibility] = useState("");
  const [bulkIsDiscountPrice, setBulkIsDiscountPrice] = useState(false);

  const handleBulkEdit = async () => {
    if (selectedRows.length === 0) {
      notify("Нет выбранных товаров для редактирования");
      return;
    }
    const bulkUpdate = {};
    if (bulkModel) bulkUpdate.model = bulkModel;
    if (bulkTitle) bulkUpdate.title = bulkTitle;
    if (bulkNewTitle) bulkUpdate.newTitle = bulkNewTitle;
    if (bulkPriceFromOne) bulkUpdate.priceFromOne = Number(bulkPriceFromOne);
    if (bulkPriceFromFive) bulkUpdate.priceFromFive = Number(bulkPriceFromFive);
    if (bulkPriceFromTen) bulkUpdate.priceFromTen = Number(bulkPriceFromTen);
    bulkUpdate.IsTobacco = bulkIsTobacco;
    if (bulkNicotineContent)
      bulkUpdate.nicotineContent = Number(bulkNicotineContent);
    if (bulkNumberOfUses)
      bulkUpdate.numberOfUses = Number(bulkNumberOfUses);
    bulkUpdate.disabled = bulkDisabled;
    bulkUpdate.notAvailable = bulkNotAvailable;
    if (bulkDiscount) bulkUpdate.discount = Number(bulkDiscount);
    if (bulkBatteryCapacity)
      bulkUpdate.batteryCapacity = Number(bulkBatteryCapacity);
    if (bulkCartridgeCapacity)
      bulkUpdate.cartridgeCapacity = Number(bulkCartridgeCapacity);
    if (bulkLiquidCapacity)
      bulkUpdate.liquidCapacity = Number(bulkLiquidCapacity);
    if (bulkColor) bulkUpdate.color = bulkColor;
    if (bulkConfiguration) bulkUpdate.configuration = bulkConfiguration;
    if (bulkMaxPower) bulkUpdate.maxPower = Number(bulkMaxPower);
    if (bulkCoilType) bulkUpdate.coilType = bulkCoilType;
    if (bulkResistance) bulkUpdate.resistance = Number(bulkResistance);
    if (bulkCompatibility) bulkUpdate.compatibility = bulkCompatibility;
    bulkUpdate.isDiscountPrice = bulkIsDiscountPrice;

    const res = await fetchData(
        `/admin/stuff`,
        "PATCH",
        { stuffIds: selectedRows, ...bulkUpdate },
        true
    );
    if (res.status === 200) {
      notify("Массовое редактирование успешно выполнено");
    } else {
      notify("Ошибка массового редактирования");
    }
  };

  const notify = (msg) => toast.success(msg);

  // Функция загрузки товаров
  const getStuff = async () => {
    let endpoint = `/stuff?from=admin`;
    if (category) {
      endpoint += `&category=${encodeURIComponent(category)}`;
    }
    const response = await fetchData(endpoint, "GET", {}, true);
    const stuffData = await response.json();
    setStuff(stuffData);
    prevStuff = stuffData;
    // После загрузки очищаем выбор
    setSelectedRows([]);
  };

  useEffect(() => {
    getStuff();
  }, [category]);

  const handleCategoryClick = (selectedCategory) => {
    // Если нажать на ту же категорию – сброс фильтра по ней
    setCategory((prevCategory) =>
        prevCategory === selectedCategory ? "" : selectedCategory
    );
  };

  const filterStuff = () => {
    if (filter === "") {
      return setStuff(prevStuff);
    }
    setCurrId(1);
    setStuff(
        prevStuff.filter(
            (value) =>
                value.title.toLowerCase().startsWith(filter.toLowerCase()) ||
                value._id === filter.toLowerCase()
        )
    );
  };

  // Обработка выбора строки для массового редактирования
  const handleRowSelect = (id, isSelected) => {
    if (isSelected) {
      setSelectedRows((prev) => [...prev, id]);
    } else {
      setSelectedRows((prev) => prev.filter((itemId) => itemId !== id));
    }
  };

  return (
      <>
        <div className="text-center p-3">
          <p className="h3">Редактирование товара</p>
          <div className="d-flex justify-content-center mb-3">
            <ButtonGroup>
              {categories.map((cat) => (
                  <Button
                      key={cat.value}
                      variant={category === cat.value ? "primary" : "outline-primary"}
                      onClick={() => handleCategoryClick(cat.value)}
                  >
                    {cat.name}
                  </Button>
              ))}
              <Button
                  variant={!category ? "primary" : "outline-primary"}
                  onClick={() => setCategory("")}
              >
                Все категории
              </Button>
            </ButtonGroup>
          </div>
          <div className="d-flex mb-3">
            <input
                type="text"
                className="form-control"
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Фильтр"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    filterStuff();
                  }
                }}
            />
            <Button variant="primary" onClick={filterStuff}>
              Поиск
            </Button>
          </div>

          {selectedRows.length > 0 && (
              <div className="bulk-edit-section mb-3 p-3 border">
                <h4>Массовое редактирование выбранных товаров</h4>
                <div className="row">
                  <div className="col-md-3">
                    <label>Model:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={bulkModel}
                        onChange={(e) => setBulkModel(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Title:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={bulkTitle}
                        onChange={(e) => setBulkTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>New Title:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={bulkNewTitle}
                        onChange={(e) => setBulkNewTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Price From One:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkPriceFromOne}
                        onChange={(e) => setBulkPriceFromOne(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label>Price From Five:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkPriceFromFive}
                        onChange={(e) => setBulkPriceFromFive(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Price From Ten:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkPriceFromTen}
                        onChange={(e) => setBulkPriceFromTen(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Is Tobacco:</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={bulkIsTobacco}
                        onChange={(e) => setBulkIsTobacco(e.target.checked)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Nicotine Content:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkNicotineContent}
                        onChange={(e) => setBulkNicotineContent(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label>Number Of Uses:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkNumberOfUses}
                        onChange={(e) => setBulkNumberOfUses(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Disabled:</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={bulkDisabled}
                        onChange={(e) => setBulkDisabled(e.target.checked)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Not Available:</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={bulkNotAvailable}
                        onChange={(e) => setBulkNotAvailable(e.target.checked)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Discount:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkDiscount}
                        onChange={(e) => setBulkDiscount(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label>Battery Capacity:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkBatteryCapacity}
                        onChange={(e) => setBulkBatteryCapacity(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Cartridge Capacity:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkCartridgeCapacity}
                        onChange={(e) => setBulkCartridgeCapacity(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Liquid Capacity:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkLiquidCapacity}
                        onChange={(e) => setBulkLiquidCapacity(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Color:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={bulkColor}
                        onChange={(e) => setBulkColor(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label>Configuration:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={bulkConfiguration}
                        onChange={(e) => setBulkConfiguration(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Max Power:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkMaxPower}
                        onChange={(e) => setBulkMaxPower(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Coil Type:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={bulkCoilType}
                        onChange={(e) => setBulkCoilType(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Resistance:</label>
                    <input
                        type="number"
                        className="form-control"
                        value={bulkResistance}
                        onChange={(e) => setBulkResistance(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-3">
                    <label>Compatibility:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={bulkCompatibility}
                        onChange={(e) => setBulkCompatibility(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label>Is Discount Price:</label>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={bulkIsDiscountPrice}
                        onChange={(e) => setBulkIsDiscountPrice(e.target.checked)}
                    />
                  </div>
                  <div className="col-md-6 d-flex align-items-end">
                    <Button variant="success" onClick={handleBulkEdit}>
                      Применить изменения к выбранным
                    </Button>
                  </div>
                </div>
              </div>
          )}


          <Table striped bordered hover>
            <thead>
            <tr>
              <th>Выбрать</th>
              <th>ID</th>
              <th>Изображение</th>
              <th>Название</th>
              <th>Описание</th>
              <th>От 1 ед</th>
              <th>От 5 ед</th>
              <th>От 10 ед</th>
              <th>Скидка (градации)</th>
              <th>Выключить</th>
              <th>Потушить</th>
              <th>✓</th>
              <th>X</th>
            </tr>
            </thead>
            <tbody>
            {stuff.length > 0 &&
                stuff
                    .slice((currId - 1) * 20, currId * 20)
                    .map((v) => (
                        <StuffRow
                            key={v._id}
                            id={v._id}
                            title={v.title}
                            priceFromOne={v.priceFromOne}
                            priceFromFive={v.priceFromFive}
                            priceFromTen={v.priceFromTen}
                            description={v.description || ""}
                            discount={v.discount || 0}
                            disabled={v.disabled || false}
                            notAvailable={v.notAvailable || false}
                            isDiscPrices={v.isDiscountPrice || false}
                            category={v.category}
                            toast={notify}
                            image={v.image}
                            onSelectChange={handleRowSelect}
                        />
                    ))}
            </tbody>
          </Table>
          <select onChange={(e) => setCurrId(Number(e.target.value))}>
            {new Array(Math.floor(stuff.length / 20) + 1)
                .fill("")
                .map((_, i) => i + 1)
                .map((v) => (
                    <option key={v} value={String(v)}>
                      {v}
                    </option>
                ))}
          </select>
        </div>
        <ToastContainer />
      </>
  );
};

export default EditStuff;
